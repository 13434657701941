import React from "react"
import { Link } from "gatsby"

const NavLink = ({ to, children, title = "Link", selected = false }) => {
  const textColor = selected ? "text-accent" : "text-secondary"
  const style = `text-base hover:text-accent transition duration-150 ${textColor}`
  return (
    <Link to={to} title={title}>
      <span className={style}>{children}</span>
    </Link>
  )
}

const Navigation = ({ locale, activePage }) => {
    
  const [locale2, setLocale2] = React.useState('en')
  const [navShow, setNavShow] = React.useState(false)

  React.useEffect(() => {
      const body = document.body
      if (typeof body !== "undefined") {
        if (navShow) {
          body.style.setProperty("overflow", "hidden")
        } else {
          body.style.removeProperty("overflow")
        }
      }
      setLocale2(locale)
    },
    [navShow, locale]
  )
  
  return (
    <>
      <header className="hidden lg:flex flex-col container m-auto px-5 max-w-screen-xl overflow-hidden my-4">
        <nav
          className="mt-auto h-full flex justify-between items-center text-sm"
          aria-label="Main Navigation"
        >
          <span className="hidden flex-1 lg:flex items-center space-x-6 font-bold text-3xl">
            <NavLink to={"/" + (locale2 === "en" ? "" : locale2)} title="Home" selected={activePage === "/"}>
              :: Lyrical Oak Whisper ::
            </NavLink>
          </span>
          
          <span className="hidden lg:flex flex-shrink-0 px-4">
            <Link to={"/" + (locale2 === "en" ? "" : locale2)} aria-label="Home" className="font-semibold">
              Home
            </Link>
          </span>
          
          <div className="hidden lg:flex sm:flex-row-reverse flex-1">
            <Link to="/" hrefLang="en" className={locale2 === "en" ? "active-navlink" : ""}>
              English
            </Link>
            {` `} :: {` `}
            <Link to="/de" hrefLang="de" className={locale2 === "de" ? "active-navlink" : ""}>
              Deutsch
            </Link>
          </div>
        </nav>
      </header>
      
      
      <div className="lg:hidden text-xl" aria-hidden={!navShow}>
        <button
          type="button"
          className="lg:hidden fixed right-0 px-2 pt-6 z-50 focus:outline-none"
          onClick={e => {
            setNavShow(!navShow)
          }}
        >
          {navShow ? (
            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          ) : (
            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          )}
        </button>
        <div className="lg:hidden flex items-center justify-center pt-6">
          <Link to={"/" + (locale2 === "en" ? "" : locale2)} aria-label="Home">
            <div className="font-semibold">
              :: Lyrical Oak Whisper ::
            </div>
          </Link>
        </div>
        {navShow && (
          <div className="absolute z-10 top-0">
            <div className="w-full h-screen fixed bg-mint-green3 opacity-90"></div>
            <button
              type="button"
              aria-label="Nav"
              className="w-full h-screen fixed cursor-auto"
              onClick={e => {
                setNavShow(!navShow)
              }}
            />
            <nav className="right-0 h-screen mt-auto py-48 px-6 fixed flex flex-col items-end font-medium text-primary tracking-widest">
              <div className="flex-grow">
                <Link to={"/" + (locale2 === "en" ? "" : locale2)} onClick={e => {
                  setNavShow(!navShow)
                }}>HOME</Link>
              </div>
              <div className="flex-grow" >
                <Link to="/" hrefLang="en" className={locale2 === "en" ? "active-navlink" : ""} onClick={e => {
                  setNavShow(!navShow)
                }}>
                  English
                </Link>
              </div>
              <div className="flex-grow">
                <Link to="/de" hrefLang="de" className={locale2 === "de" ? "active-navlink" : ""} onClick={e => {
                  setNavShow(!navShow)
                }}>
                  Deutsch
                </Link>
              </div>
            </nav>
          </div>
        )}
      </div>
    </>
      
  )
}

export default Navigation
