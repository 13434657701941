import * as React from "react"
import { MDXProvider } from "@mdx-js/react"
import Navigation from "./navigation"
import Footer from "./footer"

const LocaleContext = React.createContext({ locale: "en"})

const Layout = ({ location, children, pageContext: { locale } }) => {
  
  return (
    <LocaleContext.Provider value={{ locale }}>
      <div className="global-wrapper">
        <header className="global-header">
          <Navigation location={location} locale={locale} activePage="/"/>
        </header>
        <MDXProvider>
          <main className="px-4 py-4 rounded-2xl">{children}</main>
        </MDXProvider>
        <Footer locale={locale}/>
      </div>
    </LocaleContext.Provider>
  )
}

export { Layout, LocaleContext }
