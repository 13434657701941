import React from "react"
import { Link } from "gatsby"
import useTranslations from "../components/useTranslations"

const Footer = ({ locale, activePage }) => {
  
  const { impressum, impressumLink, privacyPolicy, privacyPolicyLink, licenses, licenseLink } = useTranslations()
    
  return (
    <footer className="text-center mb-8">
      <Link to={"/" + (locale === "en" ? "" : locale + "/") + impressumLink} aria-label={impressum} className="">
        {impressum}
      </Link> ::{` `}
      <Link to={"/" + (locale === "en" ? "" : locale + "/") + privacyPolicyLink} aria-label={privacyPolicy} className="">
        {privacyPolicy}
      </Link> ::{` `}
     <Link to={"/" + (locale === "en" ? "" : locale + "/") + licenseLink} aria-label={licenses} className="">
       {licenses}
     </Link>
    </footer>
  )
}

export default Footer
