exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-licenses-js": () => import("./../../../src/pages/licenses.js" /* webpackChunkName: "component---src-pages-licenses-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-20082022-in-der-arena-index-de-mdx": () => import("./../../../src/templates/leaf.jsx?__contentFilePath=/opt/build/repo/content/leaves/20082022-in-der-arena/index.de.mdx" /* webpackChunkName: "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-20082022-in-der-arena-index-de-mdx" */),
  "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-20082022-in-der-arena-index-mdx": () => import("./../../../src/templates/leaf.jsx?__contentFilePath=/opt/build/repo/content/leaves/20082022-in-der-arena/index.mdx" /* webpackChunkName: "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-20082022-in-der-arena-index-mdx" */),
  "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-21082022-kampf-mit-den-drachen-index-de-mdx": () => import("./../../../src/templates/leaf.jsx?__contentFilePath=/opt/build/repo/content/leaves/21082022-kampf-mit-den-drachen/index.de.mdx" /* webpackChunkName: "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-21082022-kampf-mit-den-drachen-index-de-mdx" */),
  "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-21082022-kampf-mit-den-drachen-index-mdx": () => import("./../../../src/templates/leaf.jsx?__contentFilePath=/opt/build/repo/content/leaves/21082022-kampf-mit-den-drachen/index.mdx" /* webpackChunkName: "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-21082022-kampf-mit-den-drachen-index-mdx" */),
  "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-24082022-ithaka-index-de-mdx": () => import("./../../../src/templates/leaf.jsx?__contentFilePath=/opt/build/repo/content/leaves/24082022-ithaka/index.de.mdx" /* webpackChunkName: "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-24082022-ithaka-index-de-mdx" */),
  "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-24082022-ithaka-index-mdx": () => import("./../../../src/templates/leaf.jsx?__contentFilePath=/opt/build/repo/content/leaves/24082022-ithaka/index.mdx" /* webpackChunkName: "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-24082022-ithaka-index-mdx" */),
  "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-25082022-frieden-index-de-mdx": () => import("./../../../src/templates/leaf.jsx?__contentFilePath=/opt/build/repo/content/leaves/25082022-frieden/index.de.mdx" /* webpackChunkName: "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-25082022-frieden-index-de-mdx" */),
  "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-25082022-frieden-index-mdx": () => import("./../../../src/templates/leaf.jsx?__contentFilePath=/opt/build/repo/content/leaves/25082022-frieden/index.mdx" /* webpackChunkName: "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-25082022-frieden-index-mdx" */),
  "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-29082022-butterfly-effect-index-mdx": () => import("./../../../src/templates/leaf.jsx?__contentFilePath=/opt/build/repo/content/leaves/29082022-butterfly-effect/index.mdx" /* webpackChunkName: "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-29082022-butterfly-effect-index-mdx" */),
  "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-29082022-far-below-and-above-all-index-mdx": () => import("./../../../src/templates/leaf.jsx?__contentFilePath=/opt/build/repo/content/leaves/29082022-far-below-and-above-all/index.mdx" /* webpackChunkName: "component---src-templates-leaf-jsx-content-file-path-opt-build-repo-content-leaves-29082022-far-below-and-above-all-index-mdx" */),
  "component---src-templates-legal-jsx-content-file-path-opt-build-repo-content-legal-legalnotice-index-de-mdx": () => import("./../../../src/templates/legal.jsx?__contentFilePath=/opt/build/repo/content/legal/legalnotice/index.de.mdx" /* webpackChunkName: "component---src-templates-legal-jsx-content-file-path-opt-build-repo-content-legal-legalnotice-index-de-mdx" */),
  "component---src-templates-legal-jsx-content-file-path-opt-build-repo-content-legal-legalnotice-index-mdx": () => import("./../../../src/templates/legal.jsx?__contentFilePath=/opt/build/repo/content/legal/legalnotice/index.mdx" /* webpackChunkName: "component---src-templates-legal-jsx-content-file-path-opt-build-repo-content-legal-legalnotice-index-mdx" */),
  "component---src-templates-seed-jsx-content-file-path-opt-build-repo-content-seeds-29082022-pirouetten-drehen-index-de-mdx": () => import("./../../../src/templates/seed.jsx?__contentFilePath=/opt/build/repo/content/seeds/29082022-pirouetten-drehen/index.de.mdx" /* webpackChunkName: "component---src-templates-seed-jsx-content-file-path-opt-build-repo-content-seeds-29082022-pirouetten-drehen-index-de-mdx" */)
}

